import React, { Component, Fragment } from 'react';
import { RippleButton } from './RippleButton';

export const TabPanel = (props) => props;

export const Tabs = ({tabIndex, setTab, children, className}) => {
    const tabPanels = React.Children.toArray(children);
    const activeClass = index => tabIndex===index ? 'active' : '';
    const headers = tabPanels.map((tab, index) =>
        <div key={`tab__header-${index}`} 
            className={`tab__header 
                ${activeClass(index)}`} 
            onClick = {e => index!==tabIndex && setTab(index)}>
        </div>
    );
    const panels = tabPanels.map((tab, index) =>
        <div className={`tab__panel ${activeClass(index)}`} key={`tab__panel-${index}`} >
            {tab.props.children}
        </div>
    );
    return (
        <React.Fragment>
            <nav className={`tab__header-container ${className||''}`}>
                {headers}
            </nav>
            {panels}
        </React.Fragment>
    );
}
