import { rule, milestones, placeType, mobileNumberHeader, emailRegex, directionOptions, I18nCalendar, routeTypeOptions, priceOffset, carOptions, fixedDistancePrice} from './Constants';
const moment = require('moment');
const abbreviate = require('number-abbreviate');

export const deferedPromise = () => {
  let deferedResolve;
  let promise = new Promise((resolve, reject) => {deferedResolve = resolve});
  return {promise, resolve: deferedResolve};
}

export const clip = (val,min,max) => val<min ? min : val > max ? max : val;

export const isClip = (val,min,max) => or0(val) && val>=min && val<=max;

export const is0 = val => (''+val).trim()==='0';

export const accept0 = (val, other) => is0(val) ? 0 : val ? val : other;

export const or0 = val => !!val || is0(val);

export const isInCity = p => flyDistance(p, milestones.Hanoi) < 15000;

export const isNearNoibai = p => flyDistance(p, milestones.NoibaiAirport) < 3000;

export const getPlaceType = p => isInCity(p) ? placeType.Hanoi : isNearNoibai(p) ? placeType.NoiBai : placeType.Provice;

export const getStageType = ({startType, endType}) => [startType, endType].some(type=>type===placeType.Provice) ? placeType.Provice : `${startType}-${endType}`;

export const transfrom = (direction) => (arr = []) => direction==='2-way' ? [...arr, ...[...arr].reverse().slice(1)] : [...arr];

export const hashCode = s => {
  var h = 0;
  for (let i=0; i< s.length; i++)
  	h = (h << 5) - h + s.charCodeAt(i++) | 0;
  return h;
};

export const listPointsToHash = (listPoints=[]) => hashCode(listPoints.map(each=>each.text).join(","));

let lastFindDirection = {};

export const findDirection = (listPoints, direction, setResult) => {
	listPoints = transfrom(direction)(listPoints).filter(place=>place && place.text);
	if (listPoints.length<2) return null;
	const origin = listPoints[0];
	const	destination = listPoints[listPoints.length-1];
	const	waypoints = listPoints.slice(1,-1);
	const hash = listPointsToHash(listPoints);

	if (lastFindDirection[hash]) {
		const result = lastFindDirection[hash];
		if (result && setResult) setResult(result);
		return;
	}

	const callback = function(response, status) {
		if (status == google.maps.DirectionsStatus.OK) {
			const result = calculateRouteTime(response);
			lastFindDirection[hash] = result;
			if (result && setResult) setResult(result);
		}
	};

	const directionsService = new google.maps.DirectionsService;
	directionsService.route({
		origin: origin.text,
		destination: destination.text,
		waypoints: waypoints.map(point=>({
			location: point.text,
			stopover: true
		})),
		optimizeWaypoints: false,
		travelMode: google.maps.TravelMode.DRIVING
	}, callback);
}

const locationToPlace = location => ({
	lat: location.lat(),
	lng: location.lng(),
});

const getType = (stage, startPlaces) => {
	let type = null;
	if (!stage) return type;
	const {start_location, end_location} = stage;
	const endPlace = locationToPlace(end_location);
	const startType = getPlaceType(locationToPlace(start_location));
	const endType = getPlaceType(locationToPlace(end_location));
	//console.log(startPlaces);
	const isLoop = startPlaces.some(place => {
		const d = flyDistance(place, endPlace);
		//console.log(place, endPlace, d, d<15000);
		return flyDistance(place, endPlace) < 15000;
	});
	if ([startType, endType].indexOf(placeType.Provice)>-1) {
		type = isLoop ? 'provice-2w' : 'provice';
	} else {
		type = isLoop ? 'hanoi-noibai-2w' : `${startType}-${endType}`;
	}
	return type;
}

const calculateRouteTime = (response) => {
	let distance = 0;
	let duration = 0;
	let detail = [];
	let legs = response.routes[0].legs || [];
	//console.log('----------------');
	const startPlaces = legs.map(leg=>locationToPlace(leg.start_location));
	for (let i = 0; i < legs.length; ++i) {
		const stage = legs[i];
		distance += stage.distance.value;
		duration += stage.duration.value;
		//console.log(stage, stage.start_address, stage.end_address);
		detail = [...detail, {
			distance: stage.distance.value,
			startType: getPlaceType(locationToPlace(stage.start_location)),
			endType: getPlaceType(locationToPlace(stage.end_location)),
			type: getType(stage, startPlaces.slice(0,i)),
		}];
	}
	//console.log('*****************');
	//console.log(detail);
	const result = {
		distance,
		duration,
		detail,
	}
	return result;
}

export const addHolidayPrice = (selectedDateTime, fares) => {
	//le tet , tang gia
	const selectedMoment = moment(selectedDateTime, I18nCalendar.formatDateTime);
	if (selectedMoment.isBetween(moment('27-01-2022', I18nCalendar.formatDate), moment('28-01-2022', I18nCalendar.formatDate), 'days', '[]')) {
		fares = fares.map(p=>p*=1.1);
	} else if (selectedMoment.isBetween(moment('29-01-2022', I18nCalendar.formatDate), moment('31-01-2022', I18nCalendar.formatDate), 'days', '[]')) {
		fares = fares.map(p=>p*=1.3);
	} else if (selectedMoment.isBetween(moment('01-02-2022', I18nCalendar.formatDate), moment('03-02-2022', I18nCalendar.formatDate), 'days', '[]')) {
		fares = fares.map((p, i) => i===0? p*=1.8: p*=1.3);
	}  else if (selectedMoment.isBetween(moment('04-02-2021', I18nCalendar.formatDate), moment('06-02-2021', I18nCalendar.formatDate), 'days', '[]')) {
		fares = fares.map(p=>p*=1.5);
	}
	return fares;
}

export const segmentsFare = (distance, segments, fares, selectedDateTime) => {
	let price = 0;
	if (distance > 0) {
		if (distance < rule.minDistance) {
			distance = rule.minDistance;
		}
	}
	fares = addHolidayPrice(selectedDateTime, fares);
	for(let i = 0; i < segments.length; i++) {
		if (distance <= 0) return price;
		price += Math.min(segments[i], distance)/1000 * fares[i];
		distance -= segments[i];
	}
	return price;
}

export const calculatePostage = (bookingForm) => {
	const {distance, detail, car: selectedCar, discount, withBoardName, direction, selectedDateTime, routeType, tip, listPoints, needTax} = bookingForm;
	const car = selectedCar === '4-seat' ? '5-seat' : selectedCar;
	let price = 0;
	if (!detail) {
		return segmentsFare(rule.minDistance, rule.segments, rule[car]['1w']);
	}
	let summary = detail.reduce((acc, {type, distance}) => {
		if (!type) return acc;
		acc[ type.match(/-2w/) ? '2w' : '1w' ] += distance;
		type.match(/-noibai/) && (acc['toNoibai'] += distance);
		return acc;
	}, { '1w': 0, '2w': 0, 'toNoibai': 0});
	if (summary['toNoibai'] > 0 ) {
		summary['toNoibai'] = rule.minDistance
	}

	price = segmentsFare(summary['1w'], rule.segments, rule[car]['1w'], selectedDateTime)
		+ segmentsFare(summary['2w'], rule.segments, rule[car]['2w'], selectedDateTime)
		+ summary['toNoibai']/1000 * rule[car]['toNoibai']
	;

	if (distance < 28500) {
		price -= 10000;
	}

	//le tet , tang gia
	const selectedMoment = moment(selectedDateTime, I18nCalendar.formatDateTime);
	const hour = selectedMoment.hour();

	if(car === '5-seat') {
		routeType === 'noibai-hanoi' && (price = matchFixedPrice(price, distance));
	}

	if ( ['noibai-hanoi', 'hanoi-noibai'].includes(routeType)) {
		const additionPlace = detail.length - 1;
		if (distance < 30000) {
			price += (additionPlace > 0 ? additionPlace*rule[car]['additionPlace'] : 0);
		}
		price += priceOffset[routeType][hour];
	}

	//set to min price
	if(car === '5-seat') {
		price = (routeType === 'noibai-hanoi' && price < fixedDistancePrice['minPrice']) ? fixedDistancePrice['minPrice'] : price;
	}

	(selectedCar === '4-seat') && (price-= 10000);

	if (discount) {
		price+=discount;
	}

	if (withBoardName) {
		price+=20000;
	}

	price += ( tip || 0 );

	price = Math.round(price/10000)*10000;

	if(needTax === true) {
		price *= 1.1;
	}

	return abbreviate(price, 0, 'k');
}

export const convertDistance = (distance) => {
	return distance > 100 ? (Math.round(distance/100) / 10).toLocaleString()+' km' : distance.toLocaleString()+' m';
}

const matchFixedPrice = function(price, distance) {
	if(price < fixedDistancePrice['minPrice']) {
		return fixedDistancePrice['minPrice'];
	}
	let floorDistance = Math.floor(distance / 1000);
	let finalPrice = price;
	(fixedDistancePrice[floorDistance] > 0) && (finalPrice = fixedDistancePrice[floorDistance]);
	return finalPrice;
}

const roundMoney = (a) => {
	let b = a - a | 0;
	a = a | 0;
	return b > 0.5 ? a+1 : a;
}

const divide = function(a, b) {
	let c = a/b|0, d = a-b*c;
	return [d,c];
}

export const convertTravelTime = (seconds) => {
	let travelTime = [Math.round(seconds)];
	const limit = [60,60,24,7,4,12];
	//console.log(travelTime);
	for(let i=0;i<limit.length;i++) {
		let currentUnit = travelTime[travelTime.length-1];
		//console.log(currentUnit, limit[i], travelTime, currentUnit < limit[i]);
		if (currentUnit < limit[i]) break;
		travelTime = travelTime.slice(0,travelTime.length-1).concat(divide(currentUnit,limit[i]));
	}
	let travelTimeString = '';
	const unit = ['giây','phút','giờ','ngày','tuần','tháng','năm'];
	const showSeconds = travelTime.length < 3;
	travelTime.forEach(function(e,i) {
		if (!showSeconds && i===0) return;
		if ( e>0 && i>0 ) travelTimeString = ' '+e +' '+unit[i]+ travelTimeString;
	})
	return travelTimeString;
}

//get distance between two location in flying
const flyDistance = (p1, p2) => {
	const lat = p => p.lat || p.lat();
	const lng = p => p.lng || p.lng();
	const rad = x => x * Math.PI / 180;
	const R = 6378137; // Earth’s mean radius in meter
	const dLat = rad(lat(p2) - lat(p1));
	const dLong = rad(lng(p2) - lng(p1));
	const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
			Math.cos(rad(lat(p1))) * Math.cos(rad(lat(p2))) *
			Math.sin(dLong / 2) * Math.sin(dLong / 2);
	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
	const d = R * c;
	return d; // returns the distance in meter
};

export const shortLocation = location => location
	.replace(/Vietnam/g, '')
	.replace(/Việt Nam/g, '')
	.split(',')
	.map(item=>item.trim())
	.filter((item, i, arr) => item && arr.indexOf(item) === i)
	.join(', ');

export const tripsObjectToArray = trips =>
	Object.keys(trips).map(id =>trips[id]);

export const driversFromTrips = trips => tripsObjectToArray(trips)
	.filter(trip=>trip.driverId)
	.map(({driverId, driverName})=>({driverId, driverName}))
	.filter((item, i, arr) => arr.findIndex(e => e.driverId === item.driverId) === i);

export const getUID = () => Math.round(Math.random()*1000000) + (new Date()).getTime();

export const validateMobileNumber = (mobileNumber) => {
	mobileNumber = mobileNumber.replace(/\s/g, '').replace(/\+84/g, '0');
	if (!/[0-9]/g.test(mobileNumber)) return false;
	if ([10,11].indexOf(mobileNumber.length)<0) return false;
	return mobileNumberHeader.indexOf(mobileNumber.slice(0, 3)) > 0;
}

export const validateEmail = (email) => emailRegex.test(email.toLowerCase());

export const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

export const isMobile = typeof window.orientation !== 'undefined';

let debounceEvent = null;
export const fireEventScroll = target => {
	const docTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
	const top = target.getBoundingClientRect().top + docTop - 100;
	debounceEvent && clearTimeout(debounceEvent);
	debounceEvent = setTimeout(()=> {
		parent.postMessage({top}, "*");
		if (isMobile) {
			isIOS ? window.scrollTo(top) : window.scrollTo({ top: top, behavior: 'smooth'});
		}
	}, 250);
}

export const overTime = hour => !isClip(hour,5,22);

export const airPortIndex = listPoints => listPoints.findIndex(point => {
	const text = point ? point.text.toLowerCase() : '';
	return ['noi bai', 'nội bài'].some(str => text.indexOf(str) > -1);
})

export const proviceIndex = listPoints => listPoints.findIndex(point => {
	const text = point ? point.text.toLowerCase() : '';
	return ['noi bai', 'nội bài', 'ha noi', 'hà nội'].some(str => text.indexOf(str) > -1);
})

export const getRouteType = listPoints => {
	let routeType = routeTypeOptions['provice-provice'];
	const airPortIdx = airPortIndex(listPoints);
	if (airPortIdx>-1 && airPortIdx < listPoints.length) {
    routeType = airPortIdx === 0 ? routeTypeOptions['noibai-hanoi'] :
    airPortIdx === listPoints.length -1 ? routeTypeOptions['hanoi-noibai'] :
    routeTypeOptions['hanoi-noibai-2w'];
	}
	return routeType;
}