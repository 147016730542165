import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {InputControl} from '../Controls/Input.control';
import {setForm} from '../Actions/BookingForms.action';
import {validateMobileNumber, validateEmail} from '../Core/AppUtils';
import {setUserInfo, getUserInfo} from '../Core/StorageUtils';
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";

const mapStateToProps = state => {
    return {
        name: state.bookingForm.name,
        tel: state.bookingForm.tel,
        bookingForm: state.bookingForm,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setForm: (key, value, callback) => dispatch(setForm(key, value)).then(() => callback && callback()),
    }
}

class ContactBookingForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            saveUser: false,
        };
        [
            'preSetForm',
            'toggleSaveUser',
        ].forEach(fn => this[fn] = this[fn].bind(this));
    }

    toggleSaveUser() {
        const saveUser = !this.state.saveUser;
        this.setState({saveUser});
        if (saveUser) {
            const {name, tel, email} = this.props;
            setUserInfo({name, tel, email});
        }
    }

    componentDidMount() {
        const {name, tel} = getUserInfo();
        this.preSetForm('name', true)(name);
        this.preSetForm('tel', true)(tel);
    }

    preSetForm(key, isInit) {
        return value => {
            const {setForm} = this.props;
            const callback = () => {
                const {tel, verify, setErrorMessage} = this.props;
                let error = '';
                if (!tel) {
                    error = 'Xin hãy điền số điện thoại';
                } else if (!validateMobileNumber(tel)) {
                    error = 'Số điện thoại chưa hợp lệ';
                }
                setErrorMessage && setErrorMessage(error);
                verify(!error);
            }
            setForm && setForm(key, value, isInit ? ()=>{} : callback);
        }
    }

    render() {
        const {name: saveName, tel: saveTel, email: saveEmail} = getUserInfo();
        const {name, tel, setForm} = this.props;
        const {saveUser} = this.state;
        return (
            <Fragment>
                <div className={`nb-form-control`} ref='container'>
                    <FormControl fullWidth className={`nb-form-control`} ref='container'>
                        <InputLabel htmlFor="input-with-icon-adornment">Họ tên</InputLabel>
                        <Input value={name} ref='input'
                               className='nb-form-control__input' type='text'
                               startAdornment={
                                   <InputAdornment position="start">
                                       <i className={`nb-form-control__icon nb-form-control__icon--person`}></i>
                                   </InputAdornment>
                               }
                               required={false}
                               size="normal"
                               variant="filled"
                               onChange={(e) => this.preSetForm('name')(e.target.value)}
                        />
                    </FormControl>
                </div>
                <div className={`nb-form-control`} ref='container'>
                    <FormControl fullWidth className={`nb-form-control`} ref='container'>
                        <InputLabel htmlFor="input-with-icon-adornment">Số điện thoại*</InputLabel>
                        <Input value={tel} ref='input'
                               className='nb-form-control__input' type='text'
                               startAdornment={
                                   <InputAdornment position="start">
                                       <i className={`nb-form-control__icon nb-form-control__icon--phone`}></i>
                                   </InputAdornment>
                               }
                               required={true}
                               size="normal"
                               variant="filled"
                               onChange={(e) => this.preSetForm('tel')(e.target.value)}
                        />
                    </FormControl>
                </div>
            </Fragment>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactBookingForm);
