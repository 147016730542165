export const I18nCalendar = {
	formatHour: 'HH:mm',
	formatDate: 'DD-MM-YYYY',
	formatDateTime: 'DD-MM-YYYY HH:mm',
	dayName : {
        sun : 'CN',
        mon : 'T2',
        tue : 'T3',
        wed : 'T4',
        thu : 'T5',
        fri : 'T6',
        sat : 'T7', 
    },
    monthNames : [
        "Tháng 1", "Tháng 2", 
        "Tháng 3", "Tháng 4", 
        "Tháng 5", "Tháng 6",
        "Tháng 7", "Tháng 8", 
        "Tháng 8", "Tháng 10", 
        "Tháng 11", "Tháng 12"
    ],
    today : 'HômNay',
    monthFormat : 'MMMM-YYYY',
};

export const placeOptions = {
	types: ["establishment"],
	componentRestrictions: {country: "vn"},
};

export const hint = {
	route : 'Quý khách vui lòng nhập địa chỉ để xem giá cước!!',
	business: 'Xin hãy chọn dịch vụ',
	verify: 'Quý khách vui lòng để lại số điện thoại!',
	confirm: 'Xác nhận đặt xe',
}

export const directionOptionFinals = {
    ONE_WAY: '1-way',
    TWO_WAY: '2-way'
};

export const directionOptions = [{
	value: '1-way', text: '1 chiều' 
},{
	value: '2-way', text: '2 chiều' 
}];

export const carOptions = [{
    value: '4-seat', text: '4 chỗ', icon: 'seat4'
},{
    value: '5-seat', text: '5 chỗ', icon: 'seat5'
},{
	value: '7-seat', text: '7 chỗ', icon: 'seat7'
},{
	value: '16-seat', text: '16 chỗ', icon: 'seat16'
}];

export const milestones = {
    'Hanoi' : {
        text: "Hà Nội, Hoàn Kiếm, Hà Nội, Việt Nam", 
        lat: 21.0277644, 
        lng: 105.83415979999995},
    'NoibaiAirport': {
        text: "Sân Bay Nội Bài",
        lat: 21.21302, 
        lng: 105.80275299999994},
}
export const routeTypeOptions = {
    'hanoi-noibai' : 'hanoi-noibai',
    'noibai-hanoi' : 'noibai-hanoi',
    'hanoi-noibai-2w' : 'hanoi-noibai-2w',
    'province-noibai' : 'province-noibai',
    'noibai-provice' : 'noibai-provice',
    'provice-noibai-2w' : 'provice-noibai-2w',
    'provice-provice' : 'provice-provice',
}
export const placeType = {
    Hanoi : 'hanoi',
    NoiBai : 'noibai',
    Provice : 'provice'
}
export const rule = {
    'minDistance': 32500,
    'segments' : [33000, 67000, 100, Infinity],
    '5-seat' : {
        '1w': [8100, 9600, 8500, 8500],
        '2w': [7500, 700, 1000, 100],
        'toNoibai': -1700,
        'additionPlace': 15000,
    },
    '7-seat' : {
        '1w': [9400, 12000, 10500, 10500],
        '2w': [8020, 583, 1000, 100],
        'toNoibai': -1700,
        'additionPlace': 20000,
    },
    '16-seat' : {
        '1w': [14000, 15500, 14500, 14500],
        '2w': [12000, 444, 2600, 260],
        'toNoibai': -3000,
        'additionPlace': 30000,
    }
}

export const rule_old = {
    '5-seat' : {
        'hanoi-hanoi' : 7000,
        'hanoi-noibai' : 6500,
        'noibai-hanoi' : 8500,
        'hanoi-noibai-2w' : 7500,
        'noibai-noibai' : 8500,
        'provice' : 9000,
        'provice-more' : 8500,
        'provice-2w' : 3000,
        'provice-2w-more' : 1000,
        'go-more': 5000,
        'min' : {
            'hanoi-noibai' : 200000,
            'noibai-hanoi' : 260000,
            'hanoi-noibai-2w': 450000,
            'provice': 330000,
            'provice-2w': 500000,
        },
    },
    '7-seat' : {
        'hanoi-hanoi' : 9100,
        'hanoi-noibai' : 7600,
        'noibai-hanoi' : 10100,
        'hanoi-noibai-2w' : 8600,
        'noibai-noibai' : 10100,
        'provice' : 11000,
        'provice-more' : 10500,
        'provice-2w' : 3000,
        'provice-2w-more' : 1000,
        'go-more': 7500,
        'min' : {
            'hanoi-noibai' : 250000,
            'noibai-hanoi' : 310000,
            'hanoi-noibai-2w': 520000,
            'provice': 380000,
            'provice-2w': 600000,
        },
    },
    '16-seat' : {
        'hanoi-hanoi' : 13000,
        'hanoi-noibai' : 12000,
        'noibai-hanoi' : 15000,
        'hanoi-noibai-2w' : 14000,
        'noibai-noibai' : 15000,
        'provice' : 15000,
        'provice' : 14500,
        'provice-2w' : 4200,
        'provice-2w' : 2600,
        'go-more': 8000,
        'min' : {
            'hanoi-noibai' : 350000,
            'noibai-hanoi' : 450000,
            'hanoi-noibai-2w': 750000,
            'provice': 500000,
            'provice-2w': 800000,
        },
    }
}

export const mobileNumberHeader = ['032', '033', '034', '035', '036', '037', '038', '039', '081', '082', '083', '084' ,'085', '070', '076', '077', '078', '079', '056', '058', '096', '097', '098', '086', '090', '093', '089', '091', '094', '081', '082', '083', '084', '085', '088', '056', '058', '092', '093', '099', '059'];

export const emailRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export const clockwise = (start, end, price, len = start < end ? end - start : 24 - start + end ) => [...Array(len).keys()].map(x=>(x+start)%24).reduce((r, h) => ({...r, [h]:price }), {});
export const distancewise = (start, end, price, len = end-start + 1 ) => [...Array(len).keys()].map(x=>x+start).reduce((r, d) => ({...r, [d]:price }), {});

export const priceOffset = {
    'hanoi-noibai': {
        ...clockwise(0, 24, 0),
        ...clockwise(8, 23, 10000),
        ...clockwise(6, 8, 20000),
        ...clockwise(23, 6, 40000),
    },
    'noibai-hanoi' : {
        ...clockwise(0, 24, 0),
        ...clockwise(5, 12, -10000),
        ...clockwise(12, 21, 0),
        ...clockwise(21, 22, 10000),
        ...clockwise(22, 23, 20000),
        ...clockwise(23, 5, 30000),
    }
}

export const fixedDistancePrice = {
    minPrice: 250000,
    ...distancewise(20, 30, 250000),
    ...distancewise(31, 34, 260000),
    ...distancewise(35, 37, 270000),
    ...distancewise(38, 41, 280000),
}