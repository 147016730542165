import { addBooking, removeBooking } from '../Firebase';
import { setLocalTripIds, addLocalTripId, removeLocalTripId } from '../Core/StorageUtils';
import { resetForm } from './BookingForms.action';

export const BOOKED_TRIP_ADD = 'BOOKED_TRIP_ADD';
export const BOOKED_TRIP_REMOVE = 'BOOKED_TRIP_REMOVE';
export const BOOKED_TRIP_SET = 'BOOKED_TRIP_SET';

export const addBookedTrip = () => (dispatch, getState) => {
    addBooking(getState().bookingForm).then(trip=> {
        dispatch(resetForm());
        dispatch(_addBookedTrip(trip));
        addLocalTripId(trip.id);
    });
};

export const removeBookedTrip = (trip) => (dispatch, getState) => {
    removeBooking(trip.id);
    dispatch(_removeBookedTrip(trip));
    removeLocalTripId(trip.id);
}

export const setBookedTrip = (trips) => (dispatch, getState) => {
    dispatch(_setBookedTrip(trips));
    setLocalTripIds(trips.map(e=>e.id));
};


const _setBookedTrip = (trips) => ({
    type: BOOKED_TRIP_SET,
    payload: {
        trips,
    }
});

const _addBookedTrip = (trip) => ({
    type: BOOKED_TRIP_ADD,
    payload: {
        trip,
    }
});

const _removeBookedTrip = (trip) => ({
    type: BOOKED_TRIP_REMOVE,
    payload: {
        trip,
    }
});
