import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import Fab from '@material-ui/core/Fab';
import {setForm} from '../Actions/BookingForms.action';
import {directionOptions, carOptions, CLONE_CAR, TARGET_CLONE_CAR} from '../Core/Constants';
import {findDirection, isClip, convertDistance, calculatePostage, convertTravelTime, is0} from '../Core/AppUtils';
import {rule, I18nCalendar, routeTypeOptions} from '../Core/Constants';
import {shortLocation, overTime, listPointsToHash, transfrom} from '../Core/AppUtils';
import {Tip} from '../Components/Tip';

const moment = require('moment');

const mapStateToProps = state => {
    return {
        bookingForm: state.bookingForm,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setForm: (key, value, callback) => dispatch(setForm(key, value)).then(callback),
    }
}

let oldHash = null;

class PricePreviewForm extends Component {
    constructor(props) {
        super(props);
        [
            'calculateDirection',
            'changeCarType',
            'calculatePrice'
        ].forEach(fn => this[fn] = this[fn].bind(this));
    }

    calculateDirection() {
        const {bookingForm, setForm} = this.props;
        const {listPoints, direction, distance} = bookingForm;
        let standarizedPoints = listPoints.filter(item => item && item.text);
        const newHash = listPointsToHash(transfrom(direction)(standarizedPoints));
        if (distance && oldHash && oldHash === newHash) {
            return;
        }
        oldHash = newHash;
        const setResult = (({distance, duration, detail}) => {
            distance && setForm('distance', distance);
            duration && setForm('duration', duration);
            detail && setForm('detail', detail);
        });
        findDirection([...standarizedPoints], direction, setResult);
    }

    changeCarType(carType) {
        const {setForm} = this.props;
        setForm && setForm('car', carType);
    }

    calculatePrice() {
        const {bookingForm} = this.props;
        const {
            distance = 0,
        } = bookingForm;
        return carOptions.map(car => {
            let price = '...';
            if (distance != 0) {
                price = calculatePostage({...bookingForm, car: car.value});
            }
            return {
                car,
                price
            }
        })
    }

    componentDidUpdate() {
        this.calculateDirection();
    }

    render() {
        const {bookingForm, setForm} = this.props;
        const {
            car,
            routeType,
            tip,
        } = bookingForm;

        let prices = this.calculatePrice();

        const setTip = tip => setForm('tip', tip);
        return (
            <Fragment>
                {routeType === routeTypeOptions['provice-provice'] &&
                <center><p>
                    Giá đi tỉnh chưa bao gồm phí cầu đường<br/>
                </p></center>
                }
                <div className={`route__preview`}>
                    {prices.map(({price, car: eachCar}, indexPrice) =>
                        <div
                            className={`route__preview--price-wrapper ${eachCar.value === car ? 'route__preview--price-wrapper--active' : ''}`}
                            key={`price-wrapper-${indexPrice}`} onClick={(e) => this.changeCarType(eachCar.value)}>
                            <div className={`route__preview--price-wrapper--icon route__preview--price-wrapper--icon--${eachCar.icon}`}></div>
                            <div className={`route__preview--price-wrapper--price`}>{price}</div>
                            <div className={`route__preview--price-wrapper--text`}>{eachCar.text}</div>
                        </div>
                    )}
                </div>
                {false && <Tip {...{tip, setTip}}/>}
            </Fragment>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PricePreviewForm);