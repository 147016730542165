import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { RippleButton } from '../Components/RippleButton';
import { placeOptions } from '../Core/Constants';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import { shortLocation, fireEventScroll } from '../Core/AppUtils';

export class PlaceControl extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: props.value ? (props.value.text || '') : '' ,
			options: [],
			index: 0,
			show: false,
			clickNew: false
		};
		[
			'initPlace',
			'onChange',
			'reset',
			'queryPredictions',
			'onKeyDown',
			'onSelect',
			'onFocus',
			'onClickOutside',
		].forEach(fn => this[fn] = this[fn].bind(this));
	}

	initPlace() {
		this.service = new google.maps.places.AutocompleteService();
	}

	queryPredictions() {
		const input = `${this.state.value}`;
		if (input.length < 2) return;
		const query = {
			input : `${this.state.value}`,
			types: [/*'establishment', 'geocode', 'address'*/],
			componentRestrictions: {country: 'vn'},
		};
		const callback = (predictions, status) => {
			if (status != google.maps.places.PlacesServiceStatus.OK) {
	      return;
	    }
	    let options = !predictions ? [] : predictions;
	    this.setState({
	    	options,
	    	index: 0,
	    	show: true,
	    })
		};
		this.service.getPlacePredictions(query, callback);
	}

	onKeyDown(e) {
		const {options, index} = this.state;
		const {setPlace} = this.props;
		const max = options.length;
		const clip = index => index < 0 ? max : index > max ? 0 : index;
		switch (e.keyCode) {
      case 38: 
      	return this.setState({
					index : clip(index - 1),
				});
      case 40: 
        return this.setState({
					index : clip(index + 1),
				});
      case 13:
      	return this.onSelect(index);
      default: 
        break;
    }
	}

	onSelect(idx) {
		const {options, index} = this.state;
		const {setPlace} = this.props;
		if (!options || !options[idx]) {
			return;
		}
		const value = options[idx].description;
		this.setState({
			value,
			index: idx,
			show: false,
		});
		setPlace && setPlace({text: value});
	}

	onChange(e) {
		const value = e.nativeEvent.target.value;
		let show = value.length > 0;
		this.setState((prevState, props)=>({
			value,
			show
		}));
		if (this.deferId) {
			clearTimeout(this.deferId);
		}
		this.deferId = setTimeout(()=> {
      this.queryPredictions();
		}, 750);	
	}

	onFocus(e) {
		const value = e.nativeEvent.target.value;
		let show = value.length > 0;
		this.setState({
			show
		})
		fireEventScroll(e.target);
	}

	reset(e) {
		const {setPlace} = this.props;
		this.setState((prevState, props)=>({
			index: 0,
			options: [],
			value: '',
			show: false,
		}));
		setPlace && setPlace({text: ''});
	}

	componentDidMount() {
		if (window.google) {
			this.initPlace();
		} else {
			document.addEventListener('google-init',()=> {
				this.initPlace();
			});
		}
		document.addEventListener('mousedown', this.onClickOutside);
	}

	componentWillReceiveProps(nextProps) {
		this.setState((prevState, props)=>({
			value: (nextProps.value||{}).text||'',
		}));
	}

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.onClickOutside);
  }

  onClickOutside(e) {
	if('i' === e.target.nodeName.toLowerCase()) {
		this.setState({show: false})
		return;
	}
  	const container = this.refs.container;
    if (container && container.contains(e.target)) {
			this.setState({show: true})
    } else {
    	this.setState({show: false})
    }
  }

	render() {
		const {name, className, icon, placeholder, setForm, deletable, required, addonFunc, addonIcon, addonIndex} = this.props;
		const {value, options, index, show} = this.state;
		return (
			<div className={`nb-form-control ${className||''}`} ref='container'>
				<FormControl fullWidth className={`nb-form-control ${className||''}`} ref='container'>
					<InputLabel htmlFor="input-with-icon-adornment">{placeholder}</InputLabel>
					<Input
						name={name} value={value} ref='input'
						className='nb-form-control__input' type='text'
						startAdornment={
							<InputAdornment position="start">
								<i className={`nb-form-control__icon nb-form-control__icon--${icon}`}></i>
							</InputAdornment>
						}
						endAdornment={
							<InputAdornment position="end">
								<IconButton
									aria-label="toggle password visibility"
									onClick={(e) => addonFunc(addonIndex)}
									edge="end"
								>
									<i className={`nb-form-control__icon nb-form-control__icon--${addonIcon}`}></i>
								</IconButton>
							</InputAdornment>
						}
						onChange={e=>this.onChange(e)}
						onKeyDown={e=>this.onKeyDown(e)}
						onFocus={e => this.onFocus(e)}
						required={required && !value}
						size="normal"
						variant="filled"
					/>
				</FormControl>
				<SuggestionList {...{value, options, index, show}} onSelect={this.onSelect}/>
			</div>
		)
	}
}

const SuggestionList = ({value, options, index, show, onSelect}) => !(show && options && options.length > 0) ? null : 
	<ul className='suggestion__list'>
		{options && options.map((opt, idx)=>
			<li className={`suggestion__item ${index===idx?'suggestion__item--active':''}`} key={`suggest-${idx}`} onClick={e=>onSelect(idx)}>
				<i className="suggestion__icon"></i> 
				{shortLocation(opt.description)}
			</li>
		)}
	</ul>