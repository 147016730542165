import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { InputControl } from '../Controls/Input.control';
import { directionOptions, carOptions } from '../Core/Constants';
import { isClip, convertDistance, calculatePostage, convertTravelTime, is0, shortLocation} from '../Core/AppUtils';
import { rule, I18nCalendar } from '../Core/Constants';
const moment = require('moment');

const mapStateToProps = state => {
    return {
        bookingForm : state.bookingForm,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setForm: (key,value, callback) => dispatch(setForm(key, value)).then(callback),
    }
}

class ConfirmBookingForm extends Component {
	constructor(props) {
        super(props);
        [
        ].forEach(fn => this[fn] = this[fn].bind(this));
    }
    render() {
        const {bookingForm, hideTaxi} = this.props;
    	return (
            <div className='confirmation'>
                <h4 className='text-center step__title'>Xác nhận đặt xe</h4>
                {bookingForm && 
                    <ConfirmBooking {...{bookingForm}} hideTaxi={hideTaxi}/>
                }
            </div>
        )
    }
}

//TODO : Move to component
export const ConfirmBooking = ({bookingForm, hideTaxi}) => {
    if (!bookingForm) return null;
    const {
            listPoints, 
            car, 
            direction, 
            selectedDateTime, 
            name = '', 
            tel = '', 
            email = '', 
            comment = '',
            avatar = '', 
            flightCode = '', 
            discountCode = '',
            discount=0,
            distance=0, 
            duration=0,
            routeType,
            driverName,
            withBoardName,
            detail,
            tip = 0,
            needTax
        } = bookingForm;
        const carText = (carOptions.find(opt=>opt.value===car) || carOptions[0]).text;
        const directionText = directionOptions.find(opt=>opt.value===direction).text;
        const routeDistance = convertDistance(distance);
        const routeTime = convertTravelTime(duration);
        const sum = calculatePostage(bookingForm);
        return (
            <Fragment>
                <InfoGroup label='Quý khách' value={name} />
                <InfoGroup label='Số điện thoại' value={tel} />
                <InfoGroup label='Email' value={email} />
                {hideTaxi ? null :
                    <InfoGroup label='Tài xế' value={driverName || 'Chưa có ai'} />
                }
                <hr/>
                <InfoGroupRaw label='Lộ trình'>
                    <ul className={`confirmation__value--raw`}>
                    {listPoints && listPoints.filter(point=>point).map((point,index)=>
                        <li key={`point-${index}`}>
                            <p>
                            {shortLocation(point.text)}
                            </p>
                        </li>
                    )}
                    </ul>
                </InfoGroupRaw>
                <InfoGroup label='Loại xe' value={carText} />
                <InfoGroup label='Chiều đi' value={directionText} />
                <InfoGroup label='Nhận hoá đơn VAT' value={needTax ? 'Có' : 'Không'} />
                <InfoGroup label='Mã chuyến bay' value={flightCode} />
                <InfoGroup label='Ghi chú' value={comment} />
                <InfoGroup label='Đón với bảng tên' value={withBoardName} />
                <InfoGroup label='Mã giảm giá' value={discountCode} />
                <InfoGroup label='Giờ đón khách' value={selectedDateTime} />
                <hr/>
                <p className='text-center'>
                    <b>Dự Tính </b> 
                </p>
                {/* <InfoGroup label='Thời gian đi' value={routeTime} />
                <InfoGroup label='Quãng đường' value={routeDistance} />
                <InfoGroup label='Giảm giá' value={`${discount.toLocaleString()}`} comment='VNĐ'/> */}
                <InfoGroup label='Tổng cộng' value={sum} className='bold' comment='VNĐ'/>
                <InfoGroup label='Chuyến được tip' value={tip.toLocaleString()} className='bold' comment='VNĐ'/>
                <p></p>
            </Fragment>
        )
}

const InfoGroup = ({label, value, comment, className}) => {
    return !value || is0(value) ? null :
    <div className='confirmation__group'>
        <div className='confirmation__label'>
            <strong> {label} : </strong> 
            &nbsp;
            {comment &&
                <span> ({comment}) </span>
            }
        </div>
        <div className={`confirmation__value ${className}`}>
            {value}
        </div>
    </div>
}
const InfoGroupRaw = ({label, value, comment, children}) => children ?
    <div className='confirmation__group'>
        <p className='confirmation__label'>
            <strong> {label} : </strong> 
            &nbsp;
            {comment &&
                <span> ({comment}) </span>
            }
        </p>
        {children}
    </div>
    : null;

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmBookingForm);