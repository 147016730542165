import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Tabs, TabPanel } from '../Components/Tabs';
import { Steps, StepPanel } from '../Components/Steps';
import {directionOptions, hint} from '../Core/Constants';
import RouteBookingForm from '../Forms/RouteBooking.Form';
import { Popup } from '../Components/Popup';
import CarReservationItem from '../Components/CarReservationItem';
import { ConfirmBooking } from '../Forms/ConfirmBooking.Form';
import { translate } from '../Core/Translations';
import ServiceBookingForm from '../Forms/ServiceBooking.Form';
import ContactBookingForm from '../Forms/ContactBooking.Form';
import ConfirmBookingForm from '../Forms/ConfirmBooking.Form';
import { RippleButton } from '../Components/RippleButton';
import PricePreviewForm from '../Forms/PricePrivew.Form';

import { getLocalTripIds } from '../Core/StorageUtils';
import { getBookedTrips } from '../Firebase';
import { addBookedTrip, removeBookedTrip, setBookedTrip} from '../Actions/BookedTrip.action';
import { resetForm } from '../Actions/BookingForms.action';
import { shortLocation, fireEventScroll } from '../Core/AppUtils';
import {Button as RawButton, Button} from '@material-ui/core';

const mapStateToProps = state => {
    return {
        bookingForm : state.bookingForm,
        bookedTrips : state.bookedTrips.bookedTrips,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addBookedTrip: () => dispatch(addBookedTrip()),
        removeBookedTrip: (trip) => dispatch(removeBookedTrip(trip)),
        setBookedTrip: (trips) => dispatch(setBookedTrip(trips)),
        resetForm: () => dispatch(resetForm()),
    }
}

const oldVal = {};

class AppBookingContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tabIndex : 0,
			stepIndex: 0,
			maxStep: 0,
			validSteps: {
				route: false
			},
			errorMessage: '',
			isBooking: false,
			popupItem: null,
			show: false,
			loading: true,
			maxTrip: 40,
			showError: false
		};
		[
			'setTab',
			'setMaxStep',
			'setStep',
			'nextStep',
			'resetStep',
			'verify',
			'setErrorMessage',
			'startStep',
			'endStep',
			'setPopupItem',
			'togglePopup',
			'removeBooking',
			'showErrors'
		].forEach(fn => this[fn] = this[fn].bind(this));
	}
	setTab(tabIndex) {
		this.setState((prevState, props) => ({
			...prevState,
			tabIndex,
		}));
	}
	setStep(stepIndex) {
		this.setState((prevState, props) => ({
			...prevState,
			stepIndex,
		}));
	}
	setMaxStep(maxStep) {
		this.setState((prevState, props) => ({
			...prevState,
			maxStep,
		}));
	}
	nextStep(stepIndex) {
		this.setState((prevState, props) => ({
			...prevState,
			stepIndex: Math.min(prevState.stepIndex+1, prevState.maxStep),
		}));
	}
	resetStep() {
		this.setState((prevState, props) => ({
			...prevState,
			stepIndex: 0,
			isBooking: true,
			validSteps: {},
			errorMessage: hint.route
		}));
	}
	startStep() {
		this.setState({
			isBooking: true,
		});
	}
	endStep() {
		this.setState({
			isBooking: false,
			stepIndex: 0,
			validSteps: {'route': false, 'business': true},
			errorMessage: hint.route,

		});
		const {resetForm} = this.props;
		resetForm && resetForm();

	}
	verify(step) {
		return valid => {
			this.setState((prevState, props) => ({
				...prevState,
				validSteps: {
					...prevState.validSteps,
					[step]: valid,
				}
			}));
		}
	}
	setErrorMessage(errorMessage) {
		this.setState({errorMessage});
	}
	showErrors() {
		this.setState({showError: true,});
	}
	setPopupItem(popupItem) {
		this.setState({
			popupItem
		});
		this.togglePopup();
	}
	togglePopup() {
		this.setState({
			show: !this.state.show,
		})
	}
	componentDidMount() {
		const {setBookedTrip} = this.props;
		Promise.all(getBookedTrips(getLocalTripIds()))
			.then(bookedTrips => {
				bookedTrips = bookedTrips.filter(e=>e);
				setBookedTrip(bookedTrips);
				this.setState({
					loading: false,
				})
			})
			.catch(err => {
				console.log(err);
			});
	}
	removeBooking(trip) {
		const {removeBookedTrip} = this.props;
		removeBookedTrip && removeBookedTrip(trip);
		this.togglePopup();
	}
	componentDidUpdate() {
		const {validSteps, isBooking} = this.state;
		const height = document.querySelector('#app').getBoundingClientRect().height;
		parent.postMessage({height}, "*");

		const focusToPrice = validSteps['route'] && validSteps['business'];
		if (focusToPrice && !oldVal.focusToPrice) {
			//fireEventScroll(document.querySelector('.route__preview'));
		}
		oldVal.focusToPrice = focusToPrice;

		const bookedTripIds = getLocalTripIds();
		const showFinal = !isBooking && bookedTripIds.length>0;
		if (showFinal) {
			//fireEventScroll(document.querySelector('#bookNewTrip'));
		}
	}
	render() {
		const bookedTripIds = getLocalTripIds();
		const tabsProps = {
			tabIndex : this.state.tabIndex,
			className : 'car-booking__tabs',
			setTab: this.setTab,
		}
		const stepsProps = {
			stepIndex: this.state.stepIndex,
			doShowError: this.showErrors,
			showError: this.state.showError,
			setMaxStep: this.setMaxStep,
			nextStep: this.nextStep,
			resetStep: this.resetStep,
			endStep: this.endStep,
			setStep: this.setStep,
			final: {
				text: 'Đặt xe',
				action: () => {
					this.props.addBookedTrip();
					this.endStep();
					setTimeout(()=>{
						alert('Cảm ơn quý khách! Bạn đã đặt xe thành công trên hệ thống Noibai247');
					}, 500)
				}
			}
		}
		const { bookedTrips } = this.props;
		const {validSteps, isBooking, popupItem, show, loading, maxTrip, errorMessage} = this.state;
		const tripListProps = {
			loading,
			maxTrip,
			bookedTrips,
			setPopupItem: this.setPopupItem,
		}
		const BookingStepsProps = {
			stepsProps,
			validSteps,
			verify: this.verify,
			endStep: this.endStep,
			errorMessage,
			setErrorMessage: this.setErrorMessage,
		}

		const showFinal = !isBooking && bookedTripIds.length>0;
		//console.log(showFinal, isBooking, bookedTripIds.length);
		return (
			<Tabs {...tabsProps}>
				<TabPanel header="Đặt Xe" >
					{showFinal ?
						<Fragment>
							<div className={"booked-trip-wrapper"}>
								<TripListHeader/>
								<TripList {...tripListProps}/>
								<Popup header={translate('Chi tiết đặt xe')} togglePopup={this.togglePopup} showPopup={show}>
									<PickingButtonGroup trip={popupItem} removeBooking={this.removeBooking}/>
									{popupItem ?
										<ConfirmBooking bookingForm={popupItem} hideTaxi={true}/>
										:
										<h4>Không có chuyến nào</h4>
									}
								</Popup>
								{bookedTrips.length<maxTrip &&
								<NewTripButton resetStep={this.resetStep} />
								}
							</div>
						</Fragment>
					:
						<BookingSteps {...BookingStepsProps} />
					}
				</TabPanel>
				{/*<TabPanel header="Lịch Trình">
					Bạn đang đặt 1 chuyến xe (Tối đa : 1) <br/> 
					Đăng nhập facebook để đặt chuyến tiếp theo(Tối đa: 3) và hưởng thêm nhiều khuyến mãi<br/>
					Bảng hiển thị chuyến xe<br/>
					Bảng hiển thị chuyến xe<br/>
				</TabPanel>*/}
			</Tabs>
		)
	}
}

const BookingSteps = ({stepsProps, validSteps, verify, endStep, errorMessage, setErrorMessage}) => {
	const message = errorMessage ? errorMessage :
		!validSteps['route'] ? hint.route :
		!validSteps['business'] ? hint.business :
		!validSteps['verify-user'] ? hint.verify : '';
	return (
		<Fragment>
			<Steps className='car-booking__steps' {...stepsProps}  endStep={endStep} icon='icon--route'>
				<StepPanel footer={message}
					valid={validSteps['route'] && validSteps['business'] && validSteps['verify-user']}
					icon='icon--route'>
					<RouteBookingForm verify={verify('route')}/>
					<ServiceBookingForm verify={verify('business')}/>
					<PricePreviewForm />
					<ContactBookingForm verify={validSteps['route'] ? verify('verify-user') : () => {}} setErrorMessage={setErrorMessage}/>
				</StepPanel>
			</Steps>
		</Fragment>
	)
}


const NewTripButton = ({ resetStep }) =>
	<div className='text-center'>
		<p></p>
		<Button id='bookNewTrip' className={"new-trip-btn"} size="large" variant="contained" color="primary" onClick={e=>resetStep()}>
			Đặt chuyến mới
		</Button>
		<p></p>
	</div>

const TripListHeader = ({ resetStep }) =>
	<div className='text-center car-reservation__header'>
		<p>Danh sách chuyến đi của bạn</p>
	</div>

const TripList = ({ loading, maxTrip, bookedTrips, setPopupItem}) =>
	<Fragment>
		<div className='car-reservation__item-container'>
			<div className='car-reservation__item-container-message'>
				{loading ? 'loading...' :
					<span>
						Bạn đã đặt {bookedTrips.length}
						{/*maxTrip*/} chuyến. &nbsp;
						{bookedTrips.length>=maxTrip &&
							<span className='button--link' onClick={e=> {alert('Bạn không thể đặt thêm. Hãy hủy các chuyến cũ')}}>
								Trợ giúp ?
							</span>
						}
					</span>
				}
			</div>
		</div>
		{bookedTrips && bookedTrips.length>0 &&
			<ul className='car-reservation__item-container car-reservation__item-container--grey'>
				{bookedTrips.map((item, index) =>
					<li key={`car-reservation-${index}`}
						className='car-reservation__item'
						onClick={e=>setPopupItem(item)}>
						<CarReservationItem {...{item}} hideTaxi={true}/>
					</li>
				)}
			</ul>
		}
	</Fragment>

const PickingButtonGroup = ({trip, removeBooking}) => {
	if (!trip) return null;
	return (
		<Fragment>
			<div className='button-group'>
				<span onClick={e=> confirm('Bạn muốn hủy chuyến này?') && removeBooking(trip)}>
					<RippleButton className='button button--danger button--call customer-btn'>
						Hủy
					</RippleButton>
				</span>
				<a href={`tel:0777888247`}>
					<RippleButton className='button button--info button--call  customer-btn'>
						Gọi tổng đài
					</RippleButton>
				</a>
			</div>
		</Fragment>
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(AppBookingContainer);
