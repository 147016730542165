import React, { Component, Fragment } from 'react';
//import Button from '@material-ui/core/Button';
import { Button as RawButton } from '@material-ui/core';
import { RippleButton } from '../Components/RippleButton';
import Icon from '@material-ui/core/Icon';
import { submit } from '../Actions/BookingForms.action';

export const StepPanel = (props) => props;

export class Steps extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
       const {setMaxStep, children} = this.props;
        const maxStep = React.Children.toArray(this.props.children).length;
        setMaxStep(maxStep);
    }
    render() {
        const {children, stepIndex, nextStep, final, resetStep, endStep, setStep, showError, doShowError} = this.props;
        const steps = React.Children.toArray(this.props.children);
        const currentProps = steps ? steps[stepIndex].props||null : null;
        const normal = {
            text: 'Tiếp Theo',
            action: nextStep,
        }
        const isFinal = stepIndex===steps.length-1
        const footerProps = {
            currentProps,
            isFinal, 
            normal, 
            final,
            showError,
            doShowError
        }
        return (
            <section className='step'>
                <nav className='step__header-container'>
                    {steps && steps.map((each, index)=>
                        <Fragment key={`step__header-${index}`}>
                            {index!==0 && 
                                <div className={`step__header-delimiter ${stepIndex>=index?'active':''}`}></div>
                            }
                            <div className={`step__header 
                                ${each.props.icon||''} 
                                ${stepIndex>=index?'active':''}`}></div>
                        </Fragment>
                    )}
                </nav>
                <main className='step__panels-container'>
                    {steps && steps.map((each, index, arr)=>
                        <div key={`step__panels${index}`}
                            className={`step__panel 
                                ${index===stepIndex?'active':''}`}>
                            {(index===stepIndex) && each.props.children}
                            <FooterWarning {...footerProps}/>
                            <FooterContent {...footerProps}/>
                        </div>
                    )}
                </main>
            </section>
        )
    }
}

const FooterContent = ({currentProps, isFinal, normal, final, doShowError}) =>
    // !currentProps ? null :
    // !currentProps.valid ? null:
    // !isFinal ? <Button {...normal}/> : null
{
    if(!currentProps.valid) {
        final.action = doShowError;
    }
    return <Button {...final} />
}


const FooterWarning = ({currentProps, isFinal, normal, final, showError}) =>
    !currentProps ? null :
        currentProps.valid ? null:
            showError && <div className={"warning-wrapper"}><span className='step__footer-warning'> {currentProps.footer} </span></div>

const Button = ({text, action}) =>
    <RawButton className={"custom-button"} variant="contained" color="primary" onClick={e=>action()}>
        {text}
    </RawButton>
