import React, { Fragment } from 'react';

export const Tip = ({tip, setTip}) => {
    const options = [10000, 20000, 50000, 100000];
    return (
        <div className='tip__container'>
            <div className='tip__slogan'>Khích lệ tài xế bằng tiền Tip ?</div>
            {options.map( amount =>
                <span key={`tip-${amount}`} onClick={e=>setTip(amount)}
                    className={`tip__selector ${amount===tip ? 'tip__selector--active' : ''}`} > 
                    {amount.toLocaleString()} 
                </span>
            )}
            {!tip ? null :
                <span className='tip__selector tip__selector--reset' onClick={e=>setTip(0)}>&#8203;</span>
            }
        </div>
    )
}