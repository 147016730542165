import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { DateTimeControl } from '../Controls/DateTime.control';
import { setForm } from '../Actions/BookingForms.action';
import { directionOptions, carOptions, routeTypeOptions, directionOptionFinals } from '../Core/Constants';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import {  } from '@material-ui/core/colors';

const mapStateToProps = state => {
	return {
		bookingForm: state.bookingForm,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		setForm: (key,value, callback) => dispatch(setForm(key, value)).then(callback),
	}
}

class ServiceBookingForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
		};
		[
			'preSetForm',
			'validate',
			'onChange'
		].forEach(fn => this[fn] = this[fn].bind(this));
	}
	validate() {
		const {bookingForm, verify} = this.props;
		const {direction, car, selectedDateTime, flightCode} = bookingForm;
		verify && verify(!!direction && !!selectedDateTime);
	}
	preSetForm(key) {
		return value => {
			const {setForm} = this.props;
			setForm && setForm(key, value, this.validate);
		}
	}

	onChange(e, key) {
		let value;
		switch (key) {
			case 'direction':
				value = e.target.checked ? directionOptionFinals.TWO_WAY : directionOptionFinals.ONE_WAY;
				break;
			case 'needTax':
				value = e.target.checked;
				break;
		}
		this.preSetForm(key)(value);
	}

	componentDidMount(){
		this.validate();
	}
	render() {
		const {bookingForm, setForm} = this.props;
		const {direction, car, selectedDateTime, flightCode, withBoardName, routeType} = bookingForm;
		const toggleWithBoardName = this.preSetForm('withBoardName');
		return (
			<Fragment>
				<div className={`nb-form-control`} ref='container'>
					<FormGroup>
						<FormControlLabel
							control={<Switch size="normal" color="primary" onChange={e => this.onChange(e, 'direction')} />}
							label="2 Chiều"
						/>
					</FormGroup>
					<FormGroup>
						<FormControlLabel
							control={<Switch size="normal" color="primary" onChange={e => this.onChange(e, 'needTax')} />}
							label="Nhận hoá đơn GTGT"
						/>
					</FormGroup>
				</div>
				<DateTimeControl icon='calendar'
								 className='nb-form-control__group--expand'
								 onChange={this.preSetForm('selectedDateTime')}
								 selectedDateTime={selectedDateTime}/>
			</Fragment>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceBookingForm);
